import { Link } from 'gatsby';
import gsap from 'gsap';
import React, { useRef, useState } from 'react';
import useLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';
import BottleIllustration from '../../images/illu-bottle-message.svg';
import MailIllustration from '../../images/mail.svg';
import { Button } from '../Button';
import { Noise } from '../Noise';

const minRows = 4;

export const Contact = () => {
  const [rows, setRows] = useState(minRows);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  // handle submit and unwanted redirect with getform
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const body = JSON.stringify({
      email: e.target.email.value,
      subject: e.target.subject.value || '',
      message: e.target.message.value,
    });

    fetch('/api/contact', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body,
    }).then((res) => {
      if (res.status === 200) {
        (window as any).umami?.track('contact-form-submit', {
          email: e.target.email.value,
          message: e.target.message.value,
        });
        setSubmitted(true);
        setIsLoading(false);
      } else {
        (window as any).umami?.track('contact-form-error', { error: res.statusText });
        setHasError(true);
        setIsLoading(false);
      }
    });
  };

  const handleTextareaHeight = () => {
    const textRowCount = textareaRef.current ? textareaRef.current.value.split('\n').length : rows;
    textRowCount < 12 ? setRows(minRows + textRowCount) : setRows(12);
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.wrapper`,
          start: '40% bottom',
        },
      });

      timeline.fromTo(
        'form > *',
        {
          opacity: 0,
          y: 40,
        },
        {
          duration: 1,
          opacity: 1,
          y: 0,
          stagger: 0.1,
        }
      );
    }, '#contact');

    return () => ctx.revert();
  }, []);

  return (
    <section id='contact'>
      <div className='wrapper'>
        <div className='contact-head'>
          <img
            width='100px'
            src={isSubmitted ? MailIllustration : BottleIllustration}
            alt='Flasche mit Nachricht'
          />
          <h2 className='heading'>
            {isSubmitted ? (
              <>
                Vielen Dank <br></br> für deine Nachricht{' '}
              </>
            ) : (
              'Kontakt'
            )}
          </h2>
        </div>

        {isSubmitted ? (
          <div className='success-block'>
            <Link className='al' to='/#hero' data-scroll='#hero'>
              <Button label='Zurück nach oben' />
            </Link>
          </div>
        ) : (
          <>
            <form onSubmit={(event) => handleSubmit(event)} method='POST'>
              <label htmlFor='email'>
                E-Mail<span className='asterisk'>*</span>
              </label>
              <input
                id='email'
                name='email'
                type='email'
                className='form-field'
                placeholder='Mail-Adresse'
                required
              />

              <label htmlFor='subject'>Betreff</label>
              <input
                id='subject'
                name='subject'
                type='text'
                className='form-field'
                placeholder='Betreff'
              />

              <label htmlFor='message'>
                Nachricht<span className='asterisk'>*</span>
              </label>
              <textarea
                id='message'
                ref={textareaRef}
                rows={rows}
                maxLength={800}
                name='message'
                className='form-field'
                placeholder='Deine Nachricht'
                onChange={() => handleTextareaHeight()}
                required
              />

              <Button loading={isLoading} label='Senden' type='submit' />

              {hasError && (
                <p style={{ textAlign: 'center' }}>
                  Leider ist hier etwas schief gelaufen. Versuche es direkt per{' '}
                  <a href={'mailto:' + process.env.GATSBY_RECEIVER}>E-Mail</a>
                </p>
              )}
            </form>
          </>
        )}
      </div>

      <svg
        viewBox='0 0 1440 89.427'
        xmlns='http://www.w3.org/2000/svg'
        style={{ width: '100%', height: 'auto', zIndex: 8 }}
      >
        <path
          d='m0 42.477 26.7 4.723c26.6 4.555 80.3 14.275 133.3 16.484 53.3 2.209 107-2.209 160 0 53.3 2.209 107 11.929 160 18.865 53.3 7.2 107 11.619 160 9.41 53.3-2.209 107-11.929 160-18.865 53.3-7.2 107-11.619 160-16.479 53.3-4.86 107-9.278 160-2.342 53.3 7.2 107 25.757 160 23.548 53.3-2.209 105.585-9.652 125.745-10.994s34.255 4.456 34.255 4.456v60.617h-1440z'
          fill='#1f1f1f'
          transform='translate(0 -42.477)'
        />
      </svg>
    </section>
  );
};
