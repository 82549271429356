import React, { useEffect } from 'react';
import Logo from './Logo';
import DribbbleIcon from '../images/icon-dribbble-white.svg';
import GithubIcon from '../images/icon-github-white.svg';
import WahnsinnIcon from '../images/icon-wahnsinn.svg';
import LinkedInIcon from '../images/icon-linkedin.svg';
// import InstaIcon from '../images/icon-insta-white.svg';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import useLayoutEffect from '../hooks/useIsomorphicLayoutEffect';

type FooterProps = {
  animated?: boolean;
  disabled?: boolean;
};

const Footer = ({ disabled, animated }: FooterProps) => {
  const trackClick = (target: string) => {
    (window as any).umami?.track('Footer Link', { target: target });
  };
  const socialAnchorEls = (
    <>
      <a
        href='https://christian.wahnsinn.design'
        target='_blank'
        rel='noreferrer'
        title='wahnsinn design Team Seite'
        onClick={() => trackClick('wahnsinn')}
      >
        <img src={WahnsinnIcon} alt='wahnsinn design' />
      </a>
      <a
        href='https://github.com/Cuzart'
        target='_blank'
        rel='noreferrer'
        title='Github Profil'
        onClick={() => trackClick('github')}
      >
        <img src={GithubIcon} alt='Github' />
      </a>
      <a
        href='https://dribbble.com/Funo'
        target='_blank'
        rel='noreferrer'
        title='Grafik Portfolio'
        onClick={() => trackClick('dribbble')}
      >
        <img src={DribbbleIcon} alt='Dribbble' />
      </a>
      <a
        href='https://www.linkedin.com/in/christian-heinz-1901581a3/'
        target='_blank'
        rel='noreferrer'
        title='LinkedIn Profil'
        onClick={() => trackClick('linkedin')}
      >
        <img src={LinkedInIcon} alt='Linked In' />
      </a>
      {/* <a
        href='https://www.instagram.com/funo.ism/'
        target='_blank'
        rel='noreferrer'
        title='Instagram Profil'
      >
        <img src={InstaIcon} alt='Instagram Link' />
      </a> */}
    </>
  );

  useLayoutEffect(() => {
    if (document.querySelector('.flex-wrapper') === null) return;
    let ctx = gsap.context(() => {
      gsap.fromTo(
        '.fade-up',
        {
          opacity: 0,
          y: 100,
          duration: 0.8,
          stagger: 0.1,
        },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: { trigger: '.flex-wrapper', start: '-=60 bottom' },
        }
      );
    }, '#footer');

    return () => ctx.revert();
  }, []);

  return (
    <footer id='footer' className={disabled ? 'disabled' : ''}>
      <div className='fade-up flex-wrapper'>
        <Link className='al' to='/' aria-label='Nach oben scrollen' data-scroll='#hero'>
          <Logo width={60} />
        </Link>

        {/* Desktop */}
        <div className='link-wrapper desktop-only fade-up'>
          <Link to='/imprint'>Impressum</Link>
          <Link to='/privacy'>Datenschutz</Link>
        </div>

        <div className='social-wrapper desktop-only fade-up'>{socialAnchorEls}</div>
      </div>

      <div className='mobile-wrapper fade-up'>
        <div className='link-wrapper'>
          <Link to='/imprint'>Impressum</Link>
          <Link to='/privacy'>Datenschutz</Link>
        </div>

        <div className='social-wrapper'>{socialAnchorEls}</div>
      </div>

      <div className='made-with fade-up'>
        Made with
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='3'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z'
          />
        </svg>
        in Würzburg
      </div>
    </footer>
  );
};

export default Footer;
