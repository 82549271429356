import { StaticImage } from 'gatsby-plugin-image';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import React from 'react';
import useLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';
import Flux from '../../images/flux.svg';

export const About = () => {
  // const glitch = useGlitch({ playMode: 'hover' });

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context(() => {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.content-wrapper`,
          start: '10% bottom',
        },
      });

      timeline.fromTo(
        '.text-block > *',
        {
          opacity: 0,
          y: 100,
        },
        {
          duration: 0.8,
          opacity: 1,
          y: 0,
          stagger: 0.1,
        }
      );

      gsap.fromTo(
        '.portrait-wrapper',
        {
          opacity: 0,
          y: 100,
        },
        {
          duration: 0.6,
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: `.portrait-wrapper`,
            start: '10% bottom',
          },
        }
      );
    }, '#about');

    return () => ctx.revert();
  }, []);

  return (
    <section id='about'>
      <div className='wrapper'>
        <div className='content-wrapper'>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <StaticImage className='portrait-wrapper' src={'../../images/me.webp'} alt='' />
          </div>

          <div className='text-block'>
            <h2 className='heading'>About</h2>

            <p>Ich bin Chris - Webentwickler und Designer aus Würzburg.</p>
            <p>
              Ich verbinde meine Leidenschaft zu Design mit der Faszination digitale Interaktionen
              zu entwickeln. Dabei entstehen innovative Lösungen, die sich nicht nur gut anfühlen,
              sondern auch ein positives Nutzererlebnis bieten.
            </p>

            <p>
              Aus einer Idee heraus enstand das Konzept <i>future nostalgia</i>, welches mich
              während verschiedenen Projekten begleitet hat.
            </p>
            <p>
              Der Kern liegt darin, dass auf die Dinge, die wir heute erschaffen, retrospektiv mit
              Stolz und Freude zurückgeblickt werden kann. Dabei entsteht eine neue Art der
              Wertschätzung für den kreativen Prozess.
            </p>
            <p>Diesen Gedanken möchte ich in zukünftigen Projekten weitertragen.</p>
          </div>
        </div>
        {/* 
        <div className='content-wrapper mobile-reverse'>
          <div className='text-block fade-up'>
            <p>
              Aus einer Idee heraus enstand das Konzept <i>future nostalgia</i>, welches mich
              während verschiedenen Projekten begleitet hat.
            </p>
            <p>
              Der Kern liegt darin, dass auf die Dinge, die wir heute erschaffen, retrospektiv mit
              Stolz und Freude zurückgeblickt werden kann. Dabei entsteht eine neue Art der
              Wertschätzung für den kreativen Prozess.
            </p>
            <p>Diesen Gedanken möchte ich in zukünftigen Projekten weitertragen.</p>
          </div>
          <div className='tv-wrapper' onMouseEnter={() => glitch.startGlitch()} ref={glitch.ref}>
            <div className='tv-grain fade-up'>
              <div className='animContainer' />
            </div>
            <StaticImage
              className='tv-img fade-up'
              src={'../../images/tv.webp'}
              alt='Alter Röhrenfernseher mit flackerndem Bildschirm'
            />
            <img className='scribble ' src={Scribble} alt='' />
          </div>
        </div> */}
      </div>
      <img src={Flux} alt='' className='flux' />
    </section>
  );
};
