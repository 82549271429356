import React, { useEffect, useRef } from 'react';
import IllustrationCard1 from '../../images/illu-card-1.svg';
import IllustrationCard2 from '../../images/illu-card-2.svg';
import IllustrationCard3 from '../../images/illu-card-3.svg';
import Flux from '../../images/flux.svg';
import { gsap } from 'gsap';
import useLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';

export const Cards = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = ($event: MouseEvent) => {
    if (wrapperRef.current) {
      const cards = wrapperRef.current.querySelectorAll('.card');

      cards?.forEach((card: any) => {
        const rect = card.getBoundingClientRect();

        const x = $event.clientX - rect.left;
        const y = $event.clientY - rect.top;

        card.style.setProperty('--xPos', x + 'px');
        card.style.setProperty('--yPos', y + 'px');
      });
    }
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.wrapper`,
          start: '10% bottom',
        },
      });

      timeline.fromTo(
        '.card',
        {
          opacity: 0,
          y: 100,
        },
        {
          duration: 0.8,
          opacity: 1,
          y: 0,
          stagger: 0.3,
        }
      );
    }, '#services');

    return () => ctx.revert();
  }, []);

  function createRipple(event: any) {
    const button = event.currentTarget;

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);

    const radius = diameter / 2;

    const rect = button.getBoundingClientRect();
    circle.style.width = circle.style.height = `${radius}px`;
    circle.style.left = `${event.clientX - rect.left - 80}px`;
    circle.style.top = `${event.clientY - rect.top - 80}px`;

    // circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add('ripple');

    const ripple = button.getElementsByClassName('ripple')[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  }

  const cardRef = useRef<HTMLDivElement>(null);

  return (
    <section id='services'>
      <img src={Flux} alt='' className='flux'></img>

      <div className='wrapper'>
        <h2 className='heading'>Tätigkeits&shy;bereiche</h2>
        <div
          className='cards-wrapper'
          // todo remove
          ref={wrapperRef}
          onMouseMove={(e: any) => handleMouseMove(e)}
        >
          <div className='card'>
            <div className='card-overlay'>
              <div ref={cardRef} className='card-content' onClick={createRipple}>
                <img
                  className='icon'
                  src={IllustrationCard1}
                  alt='Ein selektiertes Textfeld mit UI-Elementen'
                />
                <h3>UI Design</h3>
                {/* <p>
                  Ansprechende und intuitive Benutzerschnittstellen gehören genauso zu gutem Design
                  wie
                </p> */}
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card-overlay'>
              <div className='card-content' onClick={createRipple}>
                <img
                  className='icon'
                  src={IllustrationCard2}
                  alt='Eine Lupe zeigt auf einen zufriedenen Nutzer'
                />

                <h3>User Experience</h3>
                {/* <p>
                  Vom Prototyping bis hin zur Analyse der Usability Heuristiken - anhand von
                  verschiedenen Methodiken lässt sich die Nutzererfahrung eines Produktes positiv
                  beeinflussen.
                </p> */}
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card-overlay'>
              <div className='card-content' onClick={createRipple}>
                <img className='icon' src={IllustrationCard3} alt='Code-Klammern' />
                <h3>Entwicklung</h3>
                {/* <p>
                  Das Design wird zum Leben erweckt. Mit Hilfe von modernsten Technologien wird auf
                  Effizienz, Accessibility und Responsiveness gesetzt und es entsteht eine neues
                  digitales Produkt.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
