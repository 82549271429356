import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import CalcPoster from '../images/gallery/calc.webp';
import VocabulistPoster from '../images/gallery/vocab.webp';

export type ProjectType = {
  title: string;
  description: string;
  source: any;
  alt?: string;
  tags: string[];
  url?: string;
  videoSrc?: any;
  posterSrc?: any;
  invertPlayButton?: boolean;
  color?: string;
};

export const projects: ProjectType[] = [
  {
    title: 'Release Feed für Spotify Künstler',
    description:
      'Die Webanwendung erlaubt es sich über einen Spotify Account zu authentifizieren und deine meist gehörten Künstler automatisch zu importieren. Über die Künstler Übersicht kannst du deine gefolgten Künstler verwalten und neue Künstler hinzufügen. Die App zeigt dir die neuesten Releases deiner Künstler in einem chronologischen Feed an. Ich wollte bei diesem Projekt gezielt für mich neue Technologien ausprobieren.',
    source: (
      <StaticImage
        className='thumbnail'
        src={`../images/gallery/releasify.webp`}
        alt={'Landingpage für den IT-Dienstleister L-IT Systems'}
      />
    ),

    tags: ['Hobby-Projekt', 'Spotify-API', 'Sveltekit', 'Pocketbase', 'Shadcn/UI'],
    url: 'https://releasify.fu-no.de/',
  },
  {
    title: 'Redesign und Entwicklung L-IT Systems',
    description:
      'Für die Website des IT-Dienstleisters L-IT Systems habe ich ein neues Logo und Branding entwickelt und die Seite mit Astro - einem Framework für statische Websites umgesetzt.',
    source: (
      <StaticImage
        className='thumbnail'
        src={`../images/gallery/lit.webp`}
        alt={'Landingpage für den IT-Dienstleister L-IT Systems'}
      />
    ),

    tags: ['Kundenprojekt', 'IT-Dienstleister', 'Logo-Design', 'Branding', 'Astro'],
    url: 'https://lit.systems/',
    color: '#ffffff',
  },
  {
    title: 'Visual Storytelling Portfolio',
    description:
      'Eine minimalistische Portfolio Seite für Fotografie und Videografie Projekte. Die Projekte und Arbeiten können über Contentful verwaltet werden. Bilder werden automatisch komprimiert und in optimaler Größe ausgeliefert, um Ladezeiten zu minimieren und gleichzeitig eine hohe Qualität zu sichern.',
    source: (
      <StaticImage
        className='thumbnail'
        src={`../images/gallery/mh.webp`}
        alt={'Landingpage für das Foto- und Videografie Portfolio von Moritz Hinderer'}
      />
    ),

    tags: ['Landing Page', 'Fotografie', 'Videografie', 'Contentful', 'NextJS'],
    url: 'https://www.moritz-hinderer.de/',
  },
  {
    title: 'Vocabulist',
    description:
      'Wer kennt es nicht, man liest ein fremdsprachiges Buch oder einen Artikel und stößt auf ein fremdes Wort, welches sich nicht aus dem Kontext erschließen lässt. Klar kann man es übersetzen lassen, aber bei der nächsten Begegnung ist die Bedeutung meist schnell vergessen. Für diesen Zweck habe ich eine PWA entwickelt, mit der man möglichst schnell und nutzerfreundlich, Vokabeln in verschiedenen Sprachen übersetzen und verwalten kann.',
    source: (
      <StaticImage
        className='thumbnail'
        src={`../images/gallery/vocab.webp`}
        alt={'Vocabulist Web App mit Übersetzungsfunktion und Vokabelliste'}
      />
    ),
    posterSrc: VocabulistPoster,
    videoSrc: 'https://funo-payload.s3.eu-central-1.amazonaws.com/cdn/vocab.mp4',
    tags: ['Hobby-Projekt', 'Web App', 'Supabase', 'DeepL API', 'NextJS'],
    url: 'https://vocabulist.fu-no.de/',
  },
  {
    title: 'Fragment Objects',
    description:
      'Landingpage für nachhhaltige und handgefertigte Holzvasen und weiteren Produkten aus upgecycelten natürlichen Materialien. Das Projekt wurde mit Hilfe von Payload CMS realisiert. Das ermöglicht es Editoren ganz einfach über ein Admin Panel Inhalte in verschiedenen Sprachen zu verwalten, neue Seiten zu erstellen oder Produkte hinzuzufügen.',
    source: (
      <StaticImage
        className='thumbnail'
        src={`../images/gallery/fragment.webp`}
        alt={'Landingpage von Fragment Objects mit Produktbildern von Holzvasen'}
      />
    ),
    tags: ['Kundenprojekt', 'Landingpage', 'Shop', 'Payload CMS', 'NextJS'],
    url: 'https://fragmentobjects.com/',
  },
  {
    title: 'Sparrechner',
    description:
      'Ein vereinfachter Rechner zur Simulation einer Geldanlage mit gleichbleibenden jährlichen Renditen. Der Rechner erlaubt eine interaktive Visualisierung der Gewinne und des Endkapitals unter der Verdeutlichung des Zinseszins-Effekts.',
    source: (
      <StaticImage
        className='thumbnail'
        src={`../images/gallery/calc.webp`}
        alt={'Webseite des Sparrechners mit graphischer Darstellung der Gewinne'}
      />
    ),
    videoSrc: 'https://funo-payload.s3.eu-central-1.amazonaws.com/cdn/calc.mp4',
    posterSrc: CalcPoster,
    tags: ['Hobby-Projekt', 'Astro', 'Tailwind', 'Radix UI'],
    url: 'https://sparrechner.fu-no.de/',
  },
  {
    title: 'Spaceping Illustration',
    description:
      'Mit der ersten privat finanzierten Raumsonde die Geschichten von 1.000.000 Menschen in den interstellaren Raum zu schicken - klingt ziemlich verrückt. Das ist die Vision von Spaceping. Ich hatte die Ehre, die Idee mit dieser dreistufigen Illustration zu visualisieren.',
    source: (
      <StaticImage
        className='thumbnail'
        src={`../images/gallery/spaceping.webp`}
        alt={'Video of a dog is uploaded to spaceping and sent to space'}
      />
    ),
    tags: ['Illustration', 'Work Projekt'],
    url: 'https://www.spaceping.com/',
  },

  {
    title: 'Imaigen',
    description:
      'Eine simple Website, die mit Hilfe von generativer künstlicher Intelligenz Bilder aus Text generiert. Dafür wird das DALL-E Modell der OpenAI API verwendet, um die Bilder aus den Texteingaben zu generieren.',
    source: (
      <StaticImage
        className='thumbnail'
        src={`../images/gallery/imaigen.webp`}
        alt={'Homepage von Imaigen mit Texteingabe und generiertem Bild'}
      />
    ),
    // url: 'https://imaige-generation.vercel.app/',
    tags: ['Hobby-Projekt', 'OpenAI API', 'Generative AI', 'Mantine', 'NextJS'],
  },
  // {
  //   title: 'Cube MTB Illustration',
  //   description:
  //     'Diese Illustration habe ich aus der Motivation heraus erstellt, um vertrauter mit dem Pen Tool in Illustrator zu werden. Sie zeigt mein aktuelles Mountainbike von Cube.',
  //   source: (
  //     <StaticImage
  //       className='thumbnail'
  //       src={`../images/gallery/cube.webp`}
  //       alt={'Cube Mountain Bike'}
  //     />
  //   ),
  //   tags: ['Illustration', 'Hobby-Projekt'],
  // },

  // {
  //   title: 'Support your physio therapist',
  //   description:
  //     'Das Konzept einer App, welche die Physiotherapie von Patienten und Therapeuten digital unterstützen soll. In unseren Nutzerinterviews haben wir herausgefunden, dass sich viele Patienten nicht ausreichend angesprochen fühlen und es ein Pain Point ist, dass sie unsicher sind, wie sie ihre Übungen richtig ausführen und während der Therapie motiviert bleiben. Die App soll an den identifizierten Problemen ansetzen und mit Hilfe von Augmented Reality unmittelbares Feedback zur Ausführung der Übung geben.',
  //   source: (
  //     <StaticImage
  //       className='thumbnail'
  //       src={`../images/gallery/sypt.webp`}
  //       alt={'Prototyp Screens'}
  //     />
  //   ),
  //   tags: ['Illustration', 'Uni-Projekt', 'UX Design'],
  // },
  // {
  //   title: 'Schule 4.0',
  //   description:
  //     'Auf der Plattform Schule 4.0 können Lehrer Inhalte in Form von Übungsblättern mit Hilfe eines Editors erstellen. Dabei stehen verschiedene Bausteine zur Auswahl. Gemeinsam mit angehenden Lehrkräften konnten wir Prototypen für innovative Bausteine wie einen integrierten Stop-Motion-Editor, Übungen zum Nachbau vordefinierter Modelle und die Integration interaktiver Animationen entwickeln, bei denen die Schüler markierte Bereiche eines Frames erkunden können.',
  //   source: (
  //     <StaticImage
  //       className='thumbnail'
  //       src={`../images/gallery/schule4-0.webp`}
  //       alt={'Prototyp Screens'}
  //     />
  //   ),
  //   url: 'https://schule4-0.de/',
  //   tags: ['UX Research', 'UI/UX Design'],
  // },
  // {
  //   title: 'Lightseeker',
  //   description:
  //     'Concept Art für eine Videospiel für die Hochschulveranstaltung "Game Art". \n Dabei geht es um einen Außerirdischen, der auf einem dunklen Planeten nach Licht sucht, um zu überleben und zurück zu seiner Heimat zu gelangen.',
  //   source: (
  //     <StaticImage
  //       className='thumbnail'
  //       src={`../images/gallery/lightseeker.webp`}
  //       alt={
  //         'Ein schrulliger Außerirdischer sucht nach einer Lichtquelle auf einem dunklen Planeten'
  //       }
  //     />
  //   ),
  //   tags: ['Hochschul-Projekt', 'Character Design', 'Game Art', 'Illustration'],
  // },
  // {
  //   title: 'Wlog',
  //   description:
  //     'Im Rahmen eines Semesterprojektes wurde eine Webapplikation entwickelt, bei der sich Nutzer registrieren und einen individuellen Reiseblog anlegen können. Dabei können Orte auf einer interaktiven Karte gepinnt werden, um den Reiseverlauf chronologisch verfolgt werden.',
  //   source: (
  //     <StaticImage
  //       className='thumbnail'
  //       src={`../images/gallery/wlog.webp`}
  //       alt={'Login Dialog auf der Hero Sektion der Website'}
  //     />
  //   ),
  //   tags: ['Webanwendung', 'React', 'Python Flask', 'Hochschul-Projekt'],
  // },
  // {
  //   title: 'Mac Miller Tribute Album Cover',
  //   description:
  //     "Mac's Musik hat mich in vielen Lebenslagen begleitet und inspiriert. Also habe ich ein Tape mit einer Auswahl an Songs zusammengestellt, welches natürlich noch ein passendes Cover brauchte. Dieses sollte die verschiedenen Facetten und Emotionen der Lieder wiederspiegeln.",
  //   source: (
  //     <StaticImage
  //       className='thumbnail'
  //       src={`../images/gallery/rememories.webp`}
  //       alt={'Album Cover Art Collage aus verschriedenen Portraits von Mac Miller'}
  //     />
  //   ),
  //   tags: ['Cover Art', 'Hobby-Projekt', 'Photoshop'],
  // },
  // {
  //   title: 'Denzel Curry',
  //   description:
  //     'Meine erste Illustration mit der Software Affinity Designer. Sie zeigt eine Szene aus einer Live Performance von Denzel Curry bei COLORSxSTUDIOS.',
  //   source: (
  //     <StaticImage
  //       className='thumbnail'
  //       src={`../images/gallery/denzel.webp`}
  //       alt={'Abstrakte Illustration des Rappers Denzel Curry'}
  //     />
  //   ),
  //   tags: ['Illustration', 'Hobby-Projekt'],
  // },
  // {
  //   title: 'No Silence on Sunday',
  //   description:
  //     'Ein Logo, das ich für die Coverband "No Silence On Sunday" entworfen habe. Sie machen Rock- und Pop-Cover auf Veranstaltungen, also habe ich versucht, etwas Verspieltes, aber Raues zu kombinieren und ein Instrument in das Logo zu integrieren.',
  //   source: (
  //     <StaticImage
  //       className='thumbnail'
  //       src={`../images/gallery/nsos.webp`}
  //       alt={'Schriftzug NSOS mit eingearbeiteter Gitarre'}
  //     />
  //   ),
  //   tags: ['Branding', 'Logo Design'],
  // },
];
